<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 17.5c-1.533 0-2.833-.533-3.9-1.6-1.067-1.067-1.6-2.367-1.6-3.9s.533-2.833 1.6-3.9c1.067-1.067 2.367-1.6 3.9-1.6h10.5c1.1 0 2.042.392 2.825 1.175.783.783 1.175 1.725 1.175 2.825s-.392 2.042-1.175 2.825c-.783.783-1.725 1.175-2.825 1.175h-9.5c-.7 0-1.292-.242-1.775-.725C6.992 13.292 6.75 12.7 6.75 12s.242-1.292.725-1.775C7.958 9.742 8.55 9.5 9.25 9.5H18c.217 0 .396.07.538.212a.731.731 0 0 1 .212.538.728.728 0 0 1-.75.75H9.25a.967.967 0 0 0-.712.287.968.968 0 0 0-.288.713c0 .283.096.52.288.712A.965.965 0 0 0 9.25 13h9.5c.7 0 1.292-.242 1.775-.725.483-.483.725-1.075.725-1.775s-.242-1.292-.725-1.775C20.042 8.242 19.45 8 18.75 8H8.25c-1.1 0-2.042.392-2.825 1.175C4.642 9.958 4.25 10.9 4.25 12s.392 2.042 1.175 2.825C6.208 15.608 7.15 16 8.25 16H18c.217 0 .396.07.538.212a.731.731 0 0 1 .212.538c0 .217-.07.396-.212.538A.731.731 0 0 1 18 17.5H8.25z"
      fill="#54718E"
    />
  </svg>
</template>
